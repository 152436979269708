import React, { memo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Config from "../../config";
import { v4 as uuid } from "uuid";

const Feedback = ({
  src,
  feedbackText,
  // redirectPage,
  _id,
  feedbackText1,
  price,
  btnText,
  btnText1,
  dataFilt,
  extra,
  from,
}) => {
  const buynow = useRef();
  const navigate = useNavigate();
  const [cartItem, setCartItem] = useState(0);
  const currentURL = window.location.pathname;
  // console.log(currentURL);
  let tempClass = ``;
  if (btnText1 === "Add to Document") {
    tempClass = `after_member`;
  }

  let img = "";
  if (feedbackText == "Agreement") img = "/assets/img/svg/agreements.svg";
  else if (feedbackText == "Letter") img = "/assets/img/svg/letters.svg";
  else if (feedbackText == "Court Document")
    img = "/assets/img/svg/court-documents.svg";
  let className = ``;
  if (currentURL.includes("/legal-document") && extra === "1") {
    className = `col-md-12 col-sm-12 col-xs-12`;
  } else if (currentURL.includes("/legal-document")) {
    className = ``;
  } else {
    if (currentURL.includes("/legal-service"))
      className = `col-md-4 col-lg-3 col-sm-6 col-xs-12 mt-15`;
    else className = `col-md-6 col-lg-6 col-sm-6 col-xs-12 mt-15`;
  }

  const getCount = (id, type) => {
    let gId = ``;
    let uId = ``;
    let query = ``;
    if (type === "user") {
      uId = id;
      query = `/payment/cart-count?custId=${uId}`;
    } else if (type === "guest") {
      gId = id;
      query = `/payment/cart-count?guestId=${gId}`;
    }
    Config.get(query)
      .then((res) => {
        if (res.status) {
          setCartItem(res.data);
          localStorage.setItem("cartCount", res.data);
          document.getElementById("product_in_cart").innerText = res.data;
          document.getElementById("product_in_cart_mob").innerText = res.data;
        } else {
          Config.showToast("server error", "danger");
        }
      })
      .catch((err) => {
        console.log(err, "getCount");
      });
  };

  const addDocument = (id) => {
    document.getElementsByClassName("loader-main-wrap")[0].style.display =
      "block";
    const data = {
      userId: localStorage.getItem("user_id"),
      documentId: id,
      documentPrice : price,
    };
    Config.apipost("/user/user-add-doc", data)
      .then((res) => {
        console.log(res);
        if (res.status === 203) {
          Config.showToast(res.data.message, "danger");
          setTimeout(() => {
            document.getElementsByClassName(
              "loader-main-wrap"
            )[0].style.display = "none";
          }, 300);
        } else if (res.status === 200) {
          window.scrollTo(0, 0);
          Config.showToast("This document is added to your cart.", "success");

          if (
            localStorage.getItem("user_id") !== "" &&
            localStorage.getItem("user_id") !== null &&
            localStorage.getItem("user_id") !== undefined
          ) {
            getCount(localStorage.getItem("user_id"), "user");
          } else if (
            localStorage.getItem("guestId") !== "" &&
            localStorage.getItem("guestId") !== null &&
            localStorage.getItem("guestId") !== undefined
          ) {
            getCount(localStorage.getItem("guestId"), "guest");
          }

          setTimeout(() => {
            document.getElementsByClassName(
              "loader-main-wrap"
            )[0].style.display = "none";
          }, 300);
          navigate("/agreement");

          Config.post("/user/on-mem-rm-cart-add-doc", {
            userId: localStorage.getItem("user_id"),
          })
            .then((res) => {
              if (res.status) {
                document.getElementById("msgOnUpdatefordoc").style.display = "block";
                document.getElementsByClassName("loader-main-wrap")[0].style.display =
                  "none";
              } else {
                document.getElementsByClassName("loader-main-wrap")[0].style.display =
                  "none";
              }
            })
            .catch((err) => {
              document.getElementsByClassName("loader-main-wrap")[0].style.display =
                "none";
            })
        } else {
          Config.showToast("Please try after some time.", "danger");
          setTimeout(() => {
            document.getElementsByClassName(
              "loader-main-wrap"
            )[0].style.display = "none";
          }, 300);
        }
      })
      .catch((err) => {
        console.log(err, "addDoc");
        setTimeout(() => {
          document.getElementsByClassName("loader-main-wrap")[0].style.display =
            "none";
        }, 300);
      });
  };

  const isMember = localStorage.getItem("isMember") === "1";
  let buyDocument = true;
  if (isMember) buyDocument = false;

  let isLoggedin = true;
  if (
    (localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === null) &&
    (localStorage.getItem("user_id") === "" ||
      localStorage.getItem("user_id") === null)
  ) {
    isLoggedin = false;
  }

  const addToCart = (userId, docId) => {
    document.getElementsByClassName("loader-main-wrap")[0].style.display =
      "block";
    let custId = null;
    let guestId = null;
    if (isLoggedin) custId = userId;
    else guestId = userId;

    Config.post("/payment/cart", {
      productType: "document",
      custId: custId,
      guestId: guestId,
      lawyerId: null,
      documentId: docId,
      slotId: null,
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
      price: price,
    })
      .then((res) => {
        console.log(res);
        if (res.status) {
          // navigate(`${redirectPage}?_id=${userId}`);
          Config.showToast("This document is added to your cart.", "success", 5000);

          if (
            localStorage.getItem("user_id") !== "" &&
            localStorage.getItem("user_id") !== null &&
            localStorage.getItem("user_id") !== undefined
          ) {
            getCount(localStorage.getItem("user_id"), "user");
          } else if (
            localStorage.getItem("guestId") !== "" &&
            localStorage.getItem("guestId") !== null &&
            localStorage.getItem("guestId") !== undefined
          ) {
            getCount(localStorage.getItem("guestId"), "guest");
          }

          buynow.current.classList.remove("disabled");
          // window.scrollTo(0, 0);
          setTimeout(() => {
            document.getElementsByClassName(
              "loader-main-wrap"
            )[0].style.display = "none";
          }, 300);
        } else {
          Config.showToast(res.message, "danger");
          buynow.current.classList.remove("disabled");
          setTimeout(() => {
            document.getElementsByClassName(
              "loader-main-wrap"
            )[0].style.display = "none";
          }, 300);
          // document.getElementById("buy_now").classList.remove("disabled");
        }
      })
      .catch((err) => {
        console.log(err, "add to cart");
        buynow.current.classList.remove("disabled");
        setTimeout(() => {
          document.getElementsByClassName("loader-main-wrap")[0].style.display =
            "none";
        }, 300);
      });
  };

  const checkDocument = (docId) => {
    const userId = localStorage.getItem("user_id");
    Config.get(`/user/my-doc-validation?userId=${userId}&documentId=${docId}`)
      .then((res) => {
        if (!res.status) {
          Config.showToast(res.message, "danger");
          buynow.current.classList.remove("disabled");
        } else {
          addToCart(userId, docId);
        }
      })
      .catch((err) => {
        console.log(err, "checkDocument");
        buynow.current.classList.remove("disabled");
      });
  };

  return (
    <>
      {dataFilt === undefined && (
        <div className={className}>
          <div>
            {/* Feedback*/}
            <div className="laters_div bg-white rounded-4 h-100 shadow">
              <div className="feedback-header d-flex align-items-center mb-15">
                <div
                  className="flex-shrink-0"
                  style={{
                    background: "#00acfb",
                    padding: "4px 8px",
                    borderRadius: "8px",
                  }}
                >
                  <img loading="lazy" src={img} alt="" height={18} width={18} />
                </div>
                <div className="flex-grow-1 ms-10" style={{ color: "#00acfb" }}>
                  <p
                    className="feedback-position text-gray-dark font-size-14 m-0"
                    style={{
                      color: "#00acfb",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {feedbackText}
                  </p>
                </div>
              </div>
              <p className="feedback-text fw-medium text-dark m-0">
                {feedbackText1.slice(0, 150)}
              </p>

              {buyDocument && (
                <div className="feedback-header d-flex align-items-center mt-15">
                  <div className="flex-shrink-0">
                    <a className="btn-gray font-size-11" target="_self">
                      {`£${price} + VAT`}
                    </a>
                  </div>
                  <div className="flex-grow-1 ms-10">
                    <a
                      id="buy_now"
                      ref={buynow}
                      onClick={(e) => {
                        console.log(e.target);
                        e.target.classList.add("disabled");

                        if (isLoggedin) {
                          checkDocument(_id);
                        } else {
                          let guestId = "";
                          if (
                            localStorage.getItem("guestId") !== null &&
                            localStorage.getItem("guestId") !== ""
                          ) {
                            guestId = localStorage.getItem("guestId");
                          } else {
                            guestId = uuid();
                            localStorage.setItem("guestId", guestId);
                          }
                          addToCart(guestId, _id);
                        }
                      }}
                      className="btn  d-sm-inline-flex btn btn-sm btn-accent-2 ms-auto me-30 me-lg-0 order-2 order-lg-3 buy_now_btn"
                      target="_self"
                    >
                      {btnText}
                    </a>
                  </div>
                </div>
              )}

              <div className="text-center">
                {buyDocument && <span className="or">or</span>}
                {buyDocument && <br />}
                <a
                  className={`btn btn-accent-1 Access_free mt-6 ${tempClass}`}
                  onClick={(e) => {
                    if (btnText1 === "Add to Document") {
                      addDocument(_id);
                    }
                    if (btnText1 === "Access for Free") {
                      window.scrollTo(0, 0);
                      navigate("/pricing");
                    }
                  }}
                  target="_self"
                >
                  {btnText1}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {dataFilt !== undefined && (
        <div
          className=" isotope-item col-12 col-md-4 col-lg-3 col-sm-6 mb-30"
          data-filters={dataFilt}
        >
          <div>
            {/* Feedback*/}
            <div className="laters_div bg-white rounded-4 h-100 shadow">
              <div className="feedback-header d-flex align-items-center mb-15">
                <div
                  className="flex-shrink-0"
                  style={{
                    background: "#00acfb",
                    padding: "4px 8px",
                    borderRadius: "8px",
                  }}
                >
                  <img loading="lazy" src={img} alt="" height={18} width={18} />
                </div>
                <div className="flex-grow-1 ms-10" style={{ color: "#00acfb" }}>
                  <p
                    className="feedback-position text-gray-dark font-size-14 m-0"
                    style={{
                      color: "#00acfb",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {feedbackText}
                  </p>
                </div>
              </div>
              <p className="feedback-text fw-medium text-dark m-0">
                {feedbackText1.slice(0, 150)}...
              </p>
              {buyDocument && (
                <div className="feedback-header d-flex align-items-center mt-15">
                  <div className="flex-shrink-0">
                    <a className="btn-gray font-size-11" target="_self">
                      {`£${price} + VAT`}
                    </a>
                  </div>
                  <div className="flex-grow-1 ms-10">
                    <a
                      id="buy_now"
                      ref={buynow}
                      onClick={(e) => {
                        console.log(e.target);
                        e.target.classList.add("disabled");
                        if (isLoggedin) checkDocument(_id);
                        else {
                          let guestId = "";
                          if (
                            localStorage.getItem("guestId") !== null &&
                            localStorage.getItem("guestId") !== ""
                          ) {
                            guestId = localStorage.getItem("guestId");
                          } else {
                            guestId = uuid();
                            localStorage.setItem("guestId", guestId);
                          }
                          addToCart(guestId, _id);
                        }
                      }}
                      className="btn  d-sm-inline-flex btn btn-sm btn-accent-2 ms-auto me-30 me-lg-0 order-2 order-lg-3 buy_now_btn"
                      target="_self"
                    >
                      {btnText}
                    </a>
                  </div>
                </div>
              )}
              <div className="text-center">
                {buyDocument && <span className="or">or</span>}
                {buyDocument && <br />}
                <a
                  className={`btn btn-accent-1 Access_free mt-6 ${tempClass}`}
                  onClick={(e) => {
                    if (btnText1 === "Add to Document") {
                      addDocument(_id);
                    }
                    if (btnText1 === "Access for Free") {
                      window.scrollTo(0, 0);
                      navigate("/pricing");
                    }
                  }}
                  target="_self"
                >
                  {btnText1}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(Feedback);
